import { Flex, Text } from 'components/primitives'
import { FC } from 'react'
import Link from 'next/link'

type Props = {
  marketplaceName?: string;
  termsAndConditionsUrl?: string;
  privacyPolicyUrl?: string;
  faqsUrl?: string;
}

export const Footer: FC<Props> = ({
  marketplaceName,
  termsAndConditionsUrl,
  privacyPolicyUrl,
  faqsUrl,
}) => {
  const currentYear = new Date().getFullYear();
  return (
    <Flex
      css={{
        py: '20px',
        width: '100%',
        alignItems: 'center',
        backgroundColor: '#1f2937',
        justifyContent: 'center',
      }}
    >
      <Flex
        css={{
          gap: '$4',
          '@sm':{
            gap: '$6',
          }
        }}
      >
        {marketplaceName && (
          <Link href="/">
            <Text css={{ fontSize: '12px', whiteSpace: 'nowrap', cursor: 'pointer' }}>{`© ${currentYear} ${marketplaceName}`}</Text>
          </Link>
        )}
        {termsAndConditionsUrl && (
          <Link href={termsAndConditionsUrl}>
            <Text css={{ fontSize: '12px', whiteSpace: 'nowrap', cursor: 'pointer' }}>Terms</Text>
          </Link>
        )}
        {privacyPolicyUrl && (
          <Link href={privacyPolicyUrl}>
            <Text css={{ fontSize: '12px', whiteSpace: 'nowrap', cursor: 'pointer' }}>Privacy Policy</Text>
          </Link>
        )}
        {faqsUrl && (
          <Link href={faqsUrl}>
            <Text css={{ fontSize: '12px', whiteSpace: 'nowrap', cursor: 'pointer' }}>FAQ</Text>
          </Link>
        )}
      </Flex>
    </Flex>
  )
}