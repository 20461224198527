import { Box, Flex } from 'components/primitives'
import { FC, ReactNode, useEffect, useState } from 'react'
import Navbar from './navbar'
import { API } from 'aws-amplify'
import { Footer } from './mobo/footer/Footer'

type Props = {
  children: ReactNode
}

const Layout: FC<Props> = ({ children }) => {
  const [marketplaceConfigs, setMarketplaceConfigs] = useState<any>({})

  useEffect(() => {
    API.get(
      'mobo-marketplace',
      `/api/mobo/marketplaceConfigs/getMarketplaceConfigs`,
      {}
    ).then(marketplaceConfigs => {
      setMarketplaceConfigs(marketplaceConfigs)
    })
  }, [])
  return (
    <>
      <Box
        css={{
          background: '#000000',
          height: '100%',
          minHeight: '100vh',
          pt: 80,
        }}
      >
        <Box css={{ maxWidth: 1920, mx: 'auto' }}>
          <Navbar />
          <main>
            {children}
          </main>
        </Box>
      </Box>
      <Footer
        marketplaceName={"Pudding.xyz"}
        termsAndConditionsUrl={"terms-and-conditions"}
        privacyPolicyUrl={"privacy-policy"}
        faqsUrl={"/"}
      />
    </>
  )
}

export default Layout
