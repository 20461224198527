import {
  Box,
  Text,
  Flex,
  Input,
  Button,
} from '../primitives'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons'

import {
  useEffect,
  useState,
  forwardRef,
  ElementRef,
  ComponentPropsWithoutRef,
  FC,
  useContext,
} from 'react'
import { useDebounce } from 'usehooks-ts'
import { useMediaQuery } from 'react-responsive'
import Link from 'next/link'
import LoadingSpinner from 'components/common/LoadingSpinner'
import { SearchCollection } from 'pages/api/globalSearch'
import Img from 'components/primitives/Img'
import { ChainContext } from 'context/ChainContextProvider'
import { API } from 'aws-amplify'
import { useRouter } from 'next/router'

type Props = {
  collection?: any
  artist?: any
}

const CollectionItem: FC<Props> = ({ collection }) => {
  const router = useRouter()
  const chain = router.query.chain
  return (
    <Link
      href={`/${chain}/${collection?.username}/collection/${collection?.address}`}
      style={{ overflow: 'hidden', width: '100%', minWidth: 0 }}
    >
      <Flex
        css={{
          p: '$2',
          gap: '$3',
          cursor: 'pointer',
          borderRadius: 4,
          '&:hover': {
            background: '$gray4',
          },
          minWidth: 0,
          width: '100%',
        }}
        align="center"
      >
        <Img
          src={collection?.image || '/img/default-collection.png'}
          style={{ width: 36, height: 36, borderRadius: 4 }}
          width={36}
          height={36}
          alt="Searchbar Collection Image"
        />
        <Flex direction="column" css={{ minWidth: 0 }}>
          <Flex align="center" css={{ gap: '$1' }}>
            <Text style="subtitle1" ellipsify>
              {collection?.name}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Link>
  )
}

const ArtistItem: FC<Props> = ({ artist }) => {
  const router = useRouter()
  const chain = router.query.chain
  return (
    <Link
      href={`/${chain}/${artist?.username}`}
      style={{ overflow: 'hidden', width: '100%', minWidth: 0 }}
    >
      <Flex
        css={{
          p: '$2',
          gap: '$3',
          cursor: 'pointer',
          borderRadius: 4,
          '&:hover': {
            background: '$gray4',
          },
          minWidth: 0,
          width: '100%',
        }}
        align="center"
      >
        <Img
          src={artist?.image || '/img/default-collection.png'}
          style={{ width: 36, height: 36, borderRadius: 4 }}
          width={36}
          height={36}
          alt="Searchbar Collection Image"
        />
        <Flex direction="column" css={{ minWidth: 0 }}>
          <Flex align="center" css={{ gap: '$1' }}>
            <Text style="subtitle1" ellipsify>
              {artist?.name}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Link>
  )
}



const GlobalSearch = forwardRef<
  ElementRef<typeof Input>,
  ComponentPropsWithoutRef<typeof Input>
>(({ children, ...props }, forwardedRef) => {
  const { chain } = useContext(ChainContext)

  const [searching, setSearching] = useState(false)
  const [search, setSearch] = useState('')
  const [results, setResults] = useState<any>([])
  const [fallbackResults, setFallbackResults] = useState([])
  const [recentResults, setRecentResults] = useState<SearchCollection[]>([])
  const [showSearchBox, setShowSearchBox] = useState(false)

  const hasResults = results.collections?.length > 0 || fallbackResults.length > 0
  const hasRecentResults = recentResults.length > 0

  const debouncedSearch = useDebounce(search, 500)

  const isMobile = useMediaQuery({ query: '(max-width: 960px)' })

  useEffect(() => {
    const getSearchResults = async () => {
      setSearching(true)
      API.get(
        'mobo-marketplace',
        `/api/mobo/collections/getSearchResultsMoboData`,
        {queryStringParameters:{serachInput: debouncedSearch}}
      ).then(results => {
        setResults(results)
        setFallbackResults(results)
      })
      setSearching(false)
    }
    if (debouncedSearch.length >= 2) {
      getSearchResults()
    } else {
      setResults([])
    }
  }, [debouncedSearch])

  useEffect(() => {
    const hideBox = () => setShowSearchBox(false)

    window.addEventListener('click', hideBox)
  }, [])

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation()
      }}
      css={{ position: 'relative', width: '100%' }}
      onFocus={() => setShowSearchBox(true)}
    >
      {!isMobile && (
        <Box
          css={{
            position: 'absolute',
            top: '50%',
            left: '$4',
            zIndex: 2,
            transform: 'translate(0, -50%)',
            color: '$gray11',
          }}
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </Box>
      )}

      {isMobile && search.length > 0 && (
        <Button
          css={{
            justifyContent: 'center',
            width: '44px',
            height: '44px',
            position: 'absolute',
            right: '$4',
            zIndex: 2,
            color: '$gray10',
          }}
          type="button"
          size="small"
          color="ghost"
          onClick={() => setSearch('')}
        >
          <FontAwesomeIcon icon={faXmark} width={16} height={16} />
        </Button>
      )}

      {!showSearchBox && !isMobile && (
        <Box
          css={{
            position: 'absolute',
            top: '50%',
            right: '$4',
            zIndex: 2,

            transform: 'translate(0, -50%)',
          }}
        >
          <Text
            css={{
              color: '#ffffff',
              fontWeight: 'bold',
              display: 'none',
              '@bp1100': { display: 'block' },
            }}
          >
            ⌘K
          </Text>
        </Box>
      )}
      <Input
        {...props}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        css={{
          pl: isMobile ? 80 : 48,
          backgroundColor: isMobile ? 'transparent' : '',
          borderRadius: isMobile ? '$0' : '',
          borderBottom: isMobile ? '1px solid $gray4' : '',
          pb: isMobile ? '24px' : '',
          $$focusColor: isMobile && 'none',
          '&[placeholder]': {
            textOverflow: 'ellipsis',
            color: '#ffffff',
            fontWeight: 'bold'
          },
        }}
        ref={forwardedRef}
      />

      {(showSearchBox || isMobile) &&
        (hasResults || searching) && (
          <Box
            css={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              top: '100%',
              left: 0,
              right: 0,
              p: '$3',
              background: isMobile ? 'transparent' : '$dropdownBg',
              borderRadius: isMobile ? 0 : 8,
              zIndex: 4,
              mt: isMobile ? 0 : '$2',
              border: isMobile ? '' : '1px solid $gray7',
              overflow: 'hidden',
              width: '100%',
            }}
          >
            {results.collections.length > 0 && (
              <>
                <Text css={{color:'#ffffff', fontWeight:'700', borderBottom: '1px solid $slate8', mb:'$2', pb:'$1'}}>Collections</Text>
                {results.collections
                  .slice(0, 6)
                  .map((result: any) => (
                    <CollectionItem
                      collection={result}
                      key={`collection-${result.address}`} // Don't forget to add a unique key to each element in the map
                    />
                  ))}
              </>
            )}

            {results.artists.length > 0 && (
              <>
                <Text css={{color:'#ffffff', fontWeight:'700', borderBottom: '1px solid $slate8', mt:'$4', mb:'$2', pb:'$1'}}>Artists</Text>
                {results.artists
                  .slice(0, 6)
                  .map((result: any) => (
                    <ArtistItem
                      artist={result}
                      key={`artist-${result.username}`} // Don't forget to add a unique key to each element in the map
                    />
                  ))}
              </>
            )}

            {results.length === 0 &&
            fallbackResults.length > 0 &&
            !searching ? (
              <>
                <Text
                  style="body2"
                  color="subtle"
                  css={{
                    textAlign: 'center',
                    py: '$5',
                    justifySelf: 'center',
                  }}
                >
                  There are no results, please try again.
                </Text>
              </>
            ) : null}

            {searching && (
              <Flex align="center" justify="center" css={{ py: '$4' }}>
                <LoadingSpinner
                  css={{ width: 24, height: 24, borderWidth: '3px' }}
                />
              </Flex>
            )}
            {!searching &&
              results.length === 0 &&
              fallbackResults.length === 0 &&
              search.length > 3 && <Box css={{ p: '$4' }}>No Results</Box>}
          </Box>
        )}
    </Box>
  )
})

export default GlobalSearch
